import React, {Component} from 'react'
import {List, Icon, Divider} from 'semantic-ui-react'

class ListBreak extends Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <List.Item>                    
        <List.Content>
          <Divider horizontal>
            <h3>
            <Icon name="pointing up"/>
            On Deck
            <Icon name="pointing up"/>
            <Icon name="coffee" color="grey"/>
            <Icon name="coffee" color="grey"/>
            <Icon name="coffee" color="grey"/>
            <Icon name="coffee" color="grey"/>
            <Icon name="coffee" color="grey"/>
            <Icon name="pointing down"/>
            Streamed
            <Icon name="pointing down"/>
          </h3>
        </Divider>
      </List.Content>
    </List.Item>
    );
  }
}
export default ListBreak;