import React, { Component } from 'react';
import aws_exports from '../aws-exports';
import Amplify, { Storage, API } from 'aws-amplify';
import { Container, Header } from 'semantic-ui-react';

Amplify.configure(aws_exports);

class Projects extends Component {
  constructor(props){
    super(props);
    this.state={
      
    }  
    
  }
  render() {
    return (
      <Container text>
      <Header as='h2'>Projects</Header>
      <p>I am working a mobile game called Tower of Safety. <a href="https://goo.gl/forms/B3yBsuKvWxYaxQmr1" target="_blank">You can join the beta by filling out this form!</a> You can also play the jam version <a href="https://tigerj.itch.io/tower-of-safety" target="_blank">here on itch.io</a></p>
      <p>I have a few other projects I am working on in addition to what is mentioned above.<br /> <a href="https://discord.gg/heMYnmC" target="_blank">Please join my discord server to hangout and get all the details</a></p>
      </Container>
    );
  }
};

export default Projects