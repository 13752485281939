import React, {Component} from 'react'
import {List, Icon, Button} from 'semantic-ui-react'
import {API} from 'aws-amplify'

class UnstreamedRowSD extends Component{
  constructor(props){
    super(props)
  }
  handleStreamed(game){
    API.post('api', '/submissions/',{
      body: {
        "id": game.id,
        "video" : game.video,
        "user": game.user,
        "added": game.added,
        "streamed": true,
        "provider" : game.provider
      }
    });
  }
  render(){
    const dateString = new Date(this.props.game.added).toLocaleDateString()
    const timeString = new Date(this.props.game.added).toLocaleTimeString()
    return(
      <List.Item>
        <List.Content floated="right">
          <List.Header>            
              <Button color="green" onClick={this.handleStreamed.bind(this, this.props.game)}>
                Streamed
              </Button>
          </List.Header>
        </List.Content>
        <List.Icon name='game' color="#8b9bb4" size='large' verticalAlign='middle' />
        <List.Content>
          <List.Header><a href={this.props.game.id} target="_blank">{this.props.game.id}</a></List.Header>
          <List.Description>By {this.props.game.user}<Icon name={this.props.game.provider}/>, Submitted On {dateString} {timeString}</List.Description>
        </List.Content>
      </List.Item>
    )
  }
}

export default UnstreamedRowSD