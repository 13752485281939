import React, { Component } from 'react';
import aws_exports from '../aws-exports';
import Amplify, { Storage, API } from 'aws-amplify';

Amplify.configure(aws_exports);

class AddGame extends Component {
  constructor(props){
    super(props);
    this.state={
      link:'',
      twitch_username:''
    }  
    this.handleChangeLink = this.handleChangeLink.bind(this);
    this.handleChangeTwitchUsername = this.handleChangeTwitchUsername.bind(this);
  }

  handleChangeLink(event) {
    this.setState({link: event.target.value});
  }
  handleChangeTwitchUsername(event) {
    this.setState({twitch_username: event.target.value});
  }
  post = async () => {
    console.log('calling api');
    const response = await API.post('api', '/games',{
      body: {
        id:this.state.link,
        added: Date.now(),
        twitch_username:this.state.twitch_username
      }
    });
  }
  
  render() {
    return (
      <div>
          <p>Add Game</p>
          link <input type="url" value={this.state.link} onChange={this.handleChangeLink} /><br />
          twitch username <input type="text" value={this.state.twitch_username} onChange={this.handleChangeTwitchUsername} /><br />
          <button onClick={this.post}>Add Game</button>
        </div>
    );
  }
};

export default AddGame