import React from 'react'
import aws_exports from '../aws-exports';
import Amplify, { Analytics, Storage, API } from 'aws-amplify';
import {Container, Header} from 'semantic-ui-react'

Amplify.configure(aws_exports);

function Home(props) {
    return(
      <Container text>
        <Header as='h2'>TigerJ Games - release notes</Header>
        <p>v 0.0.0.3 - Ridge Rd</p>
        <ul>
          <li>Reversed streamed ordering</li>
          <li>Refactored queries into components</li>
          <li>Prepared for LD43</li>
        </ul>
        <p>v 0.0.0.2 - Thorne St</p>
        <ul>
          <li>Updated with dynamoDB</li>
          <li>Created nightbot command and linked to special lambda for putting records in dynamo</li>
          <li>Updated lambda with discord/twitch conditionals based off of the nested provider from Nightbot</li>
          <li>Tested with viewers on twitch, used separt dashboard I build to manage entries from</li>
          <li>fixed bug with streamed hyperlinks not working</li>
          <li>added theme based off of <a href="https://twitter.com/Endesga" target="_blank">@Endesga's</a> Edge32 pallet</li>
        </ul>
        <p>v 0.0.0.1 - VanOrden St</p>
        <ul>
          <li>Initial build using aws amplify, react, material-ui, then removed material-ui and replaced with semantic-ui-react. Also using the curi router.</li>
          <li>Completly serverless website. what you are seeing is using s3, lambda, api gateway and dynamoDB</li>
          <li>Have a great day!</li>
        </ul>
      </Container>
    );
}
export default Home;