import React,{Component} from 'react';
import {Link} from '@curi/react-dom';
import { Menu, Icon, Button } from 'semantic-ui-react'

class NavMenu extends Component{
  constructor(props){
    super(props);
  }
  state = {}

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  render(){
    const { activeItem } = this.state
    return(
      <Menu>
        <Menu.Item as={Link} to='Home' name='reviews' active={activeItem === 'reviews'} onClick={this.handleItemClick}>
          <Icon name='home' size='large' />
          TigerJ Games
        </Menu.Item>
        <Menu.Item as={Link} to='Game List' name="gamelist" active={activeItem ==="gamelist"} onClick={this.handleItemClick}>
          <Icon name='list' size='large' />
          Game List
        </Menu.Item>
        <Menu.Item as={Link} to='Projects' name="projects" active={activeItem ==="projects"} onClick={this.handleItemClick}>
          <Icon name='cube' size='large' />
          Projects
        </Menu.Item>
        <Menu.Item target="_blank" position="right">
          <a href="https://twitch.tv/tiger_j" target="_blank">
          <Icon color="violet" name='twitch' size="large" />
          </a>
          <a href="https://twitter.com/stalkjimmy" target="_blank">
          <Icon color='blue' name='twitter' size="large" />
          </a>
          <a href="https://www.youtube.com/channel/UC3kuqM4hpXXrVDWNst0uDng" target="_blank">
          <Icon color='red' name='youtube' size="large" />
          </a>
        </Menu.Item>
    </Menu>
    )
  }
}
export default NavMenu;