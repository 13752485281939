import React, {Component} from 'react'
import {List, Icon} from 'semantic-ui-react'

class UnstreamedRow extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const dateString = new Date(this.props.game.added).toLocaleDateString()
    const timeString = new Date(this.props.game.added).toLocaleTimeString()
    return(
      <List.Item>
        <List.Icon name='game' color="#8b9bb4" size='large' verticalAlign='middle' />
        <List.Content>
          <List.Header><a href={this.props.game.id} target="_blank">{this.props.game.id}</a></List.Header>
          <List.Description>By {this.props.game.user}<Icon name={this.props.game.provider}/>, Submitted On {dateString} {timeString}</List.Description>
        </List.Content>
      </List.Item>
    )
  }
}

export default UnstreamedRow