import Home from './components/Home';
import Gamelist from './components/Gamelist';
import AddGame from './components/Addgame';
import PostGame from './components/PostGame';
import Projects from './components/Projects';
import Notfound from './components/Notfound';
import StreamerDash from './components/StreamerDash';


export default[
    {
        name:'Home',
        path:'',
        response(){
            return {
                body: Home
            }
        }
    },
    {
        name:'Game List',
        path:'gamelist',
        response(){
            return {
                body:Gamelist
            }
        }
    },
    {
      name:"Add Game",
      path:"addgame",
      response(){
        return{
          body:AddGame
        }
      }
    },
    {
      name:"Post Game",
      path:"postgame",
      response(){
        return{
          body:PostGame
        }
      }
    },
    {
      name:"Projects",
      path:"projects",
      response(){
        return{
          body:Projects
        }
      }
    },
    {
      name:"StreamerDash",
      path:"streamerdash",
      response(){
        return{
          body:StreamerDash
        }
      }
    },
    {
        name:'Catch All',
        path:'(.*)',
        response(){
            return{
                body:Notfound
            }
        }
    }
];