import React, { Component } from 'react';
import aws_exports from '../aws-exports';
import Amplify, { Storage, API } from 'aws-amplify';


Amplify.configure(aws_exports);



class PostGame extends Component {
  constructor(props){
    super(props);
    this.state={
    }  
    this.post = this.post.bind(this);
  } 
  post = async () => {
    const queryString = require('query-string');
    const parsed = queryString.parse(window.location.search);
    console.log('calling api');
    const response = await API.post('api', '/submissions/',{
      body: {
        id:parsed.id,
        added: Date.now(),
        user:parsed.user,
        streamed:false
      }
    });
  }
  render() {
    this.post();
    return (
      <div>Congrats! You game has been submited!</div>
    );
  }
};

export default PostGame