import React, { Component } from 'react'
import aws_exports from '../aws-exports'
import Amplify, { API } from 'aws-amplify'
import { List, ListItem, Loader, ListContent, ListHeader, Divider } from 'semantic-ui-react'
import ListBreak from './ListBreak'
import UnstreamedRow from './UnstreamedRow';
import StreamedRow from './StreamedRow';

Amplify.configure(aws_exports);

class GamesQuery extends Component {
  constructor(props){
    super(props);
    this.state={
      games:null,
      unstreamed:null,
      streamed:null,
      streamedCount:0
    }
  }

  componentDidMount(){
    this._asyncRequest = API.get('api', '/submissions/'+new Date().toString()).then(
      games => {
        let streamed=[];
        let unstreamed=[];
        let streamedCount=0;
        console.log(games);
        games.sort(function(a, b){return a.added - b.added});
        games.forEach(game => {
          if(game.streamed!=false){
            streamed.push(game);
            streamedCount=streamed.length;
          }
          else{
            unstreamed.push(game);
          }
        });
        streamed.sort(function(a, b){return b.added - a.added});
        this._asyncRequest = null;
        this.setState({games:games, streamed:streamed, unstreamed:unstreamed, streamedCount:streamedCount});
      }
    );
  }
  render() {
    if(this.state.games===null){
      return(
        <List.Item>
          <ListContent>
            <Loader active inline='centered' />
          </ListContent>
        </List.Item>        
      );
    }
    else{
      const gamelist = this.state.unstreamed.map((game) =>
        <UnstreamedRow game={game}/>
      );
      const streamedlist = this.state.streamed.map((game)=>
        <StreamedRow game={game}/>  
      );
      return(
      <List divided verticalAlign='middle'>
      <ListItem>
        <ListHeader>
          <h2>Total Games Streamed: {this.state.streamedCount}</h2>
        </ListHeader>
        <List.Description>add your game! <a href="https://twitch.tv/tiger_j" target="_blank">follow the twitch channel</a> and during a broadcast use <em>!tigerj &lt;game link&gt;</em></List.Description>
        <Divider/>
      </ListItem>
        {gamelist}
        <ListBreak/>
        {streamedlist}
      </List>);
    }
  }
};

export default GamesQuery