// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "tigerjgames-20181028121705--hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d11l5w6ildiag0.cloudfront.net",
    "aws_mobile_analytics_app_id": "04b8e30f722d4a0ba2fd41f2fcda6852",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "submissions",
            "region": "us-east-1"
        },
        {
            "tableName": "submissions2",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://541pxlrn0a.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
