import React from 'react'
import GamesQuery from './GamesQuery'
import { Grid, Image, Container, Header, Card, Icon } from 'semantic-ui-react'
import humble from '../images/PartnerStampRed.png'

export default function Gamelist() {
  return(
    <Grid>
      <Grid.Column width={3}>
      <Card centered={true}>
        <Card.Content>
          <Card.Header><a href="https://discord.gg/heMYnmC" target="_blank">Join TigerJ's <Icon name="discord"/> Discord</a></Card.Header>
          <Card.Meta>
            or invite others<br />https://discord.gg/heMYnmC
          </Card.Meta>
          <Card.Description></Card.Description>
        </Card.Content>
        {/* <Card.Content extra>
          
        </Card.Content> */}
      </Card>
      <a href="https://www.humblebundle.com/store?partner=tigerj" target="_blank">
      <Card centered={true}>
        <Image src={humble} size="small" centered={true} />
        <Card.Content>
          <Card.Header>Visit the Humble Store!</Card.Header>
          <Card.Meta>
            Buy anything using this link to send some money back to the channel
          </Card.Meta>
          <Card.Description>Thanks!</Card.Description>
        </Card.Content>
        {/* <Card.Content extra>
          
        </Card.Content> */}
      </Card>
      </a>
      </Grid.Column>
      <Grid.Column width={10}>
        <GamesQuery />
      </Grid.Column>
      <Grid.Column width={3}>
      <Container textAlign="center">
        <Header as="h3">TigerJ's Game(s)</Header>
      </Container>
      <Card centered={true}>
        <Card.Content>
          <Card.Header>Tower of Safety</Card.Header>
          <Card.Meta>
          <a href="https://goo.gl/forms/B3yBsuKvWxYaxQmr1" target="_blank">You can join the beta by filling out this form!</a>
          </Card.Meta>
          <Card.Description>Developed for OMG Jam 3 in the summer of 2018 Tower of Safety is being released in Feburary for mobile!</Card.Description>
        </Card.Content>
      </Card>
      </Grid.Column>
    </Grid>
  )
}