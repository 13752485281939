import React, {Component} from 'react'
import {List, Icon, Button} from 'semantic-ui-react'

class StreamedRow extends Component{
  constructor(props){
    super(props)
    this.checkVideo = this.checkVideo.bind(this)
  }
  checkVideo(game){
    if(game.video!="#"){
      return(
        <List.Content floated="right">
          <List.Header>
            <a href={game.video} target="_blank">
              <Button color="violet">
                <Icon name="twitch" color="white"/>twitch
              </Button>
            </a>
          </List.Header>
        </List.Content>
      );
    }
    else{
      return(
        <List.Content floated="right">
          <List.Header>
            video link coming soon! follow me on twitch!
          </List.Header>
        </List.Content>
      );
    }
  }
  render(){
    const dateString = new Date(this.props.game.added).toLocaleDateString()
    const timeString = new Date(this.props.game.added).toLocaleTimeString()
    return(
      <List.Item>        
        {this.checkVideo(this.props.game)}
        <List.Icon name='game' color="violet" size='large' verticalAlign='middle' />
        <List.Content>
          <List.Header><a href={this.props.game.id} target="_blank">{this.props.game.id}</a></List.Header>
          <List.Description>By {this.props.game.user}<Icon name={this.props.game.provider}/>, Submitted On {dateString} {timeString}</List.Description>
        </List.Content>
      </List.Item>
    );
  }
}
export default StreamedRow