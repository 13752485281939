import React, {Component} from 'react'
import {List, Icon, Button, Input} from 'semantic-ui-react'
import {API} from 'aws-amplify'

class StreamedRowSD extends Component{
  constructor(props){
    super(props)
    this.checkVideo = this.checkVideo.bind(this)
    this.handleVideo = this.handleVideo.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }
  handleVideo(event){
    API.post('api', '/submissions/',{
      body: {
        "id": this.props.game.id,
        "video" : event.target.value,
        "user": this.props.game.user,
        "added": this.props.game.added,
        "streamed": this.props.game.streamed,
        "provider" : this.props.game.provider
      }
    });
  }
  handleDelete(game){
    // const params = {
    //   TableName:"submissions2",
    //   Key:{
    //       "id":game.id
    //   }
    // }
    API.del("api", "/submissions/object/"+game.id).then(response => {
      // Add your code here
    }).catch(error => {
        console.log(error.response)
    });
  }
  checkVideo(game){
    if(game.video!="#"){
      return(
        <List.Content floated="right">
          <List.Header>
            <a href={game.video} target="_blank">
              <Button color="violet">
                <Icon name={game.provider} color="white"/>twitch
              </Button>
            </a>
          </List.Header>
        </List.Content>
      );
    }
    else{
      return(
        <List.Content floated="right">
          <List.Header>
            <Input name="video" onChange={this.handleVideo}></Input>
          </List.Header>
        </List.Content>
      );
    }
  }
  render(){
    const dateString = new Date(this.props.game.added).toLocaleDateString()
    const timeString = new Date(this.props.game.added).toLocaleTimeString()
    return(
      <List.Item>
        <List.Content floated="right">
          <List.Header>            
              <Button color="red" onClick={this.handleDelete.bind(this, this.props.game)}>
                <Icon name={this.props.game.provider} color="white"/>DELETE
              </Button>
          </List.Header>
        </List.Content>
        {this.checkVideo(this.props.game)}
        <List.Icon name='game' color="violet" size='large' verticalAlign='middle' />
        <List.Content>
          <List.Header><a href={this.props.game.id} target="_blank">{this.props.game.id}</a></List.Header>
          <List.Description>By {this.props.game.user}<Icon name={this.props.game.provider}/>, Submitted On {dateString} {timeString}</List.Description>
        </List.Content>
      </List.Item>
    );
  }
}
export default StreamedRowSD