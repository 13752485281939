import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {curi} from '@curi/router';
import Browser from '@hickory/browser';
import routes from './routes';
import {curiProvider} from '@curi/react-dom';
import NavMenu from './components/NavMenu';
import './semantic/dist/semantic.min.css';

const history = Browser();
const router = curi(history,routes);
const Router = curiProvider(router);


ReactDOM.render((
  <div>
      <Router>  
      {({response}) =>{
          const {body:Body} = response;
          return(
            <div>
                <NavMenu />                           
                <Body response={response} />
            </div>
          );
      }}
      </Router>
      </div>
    ), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
